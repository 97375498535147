import React, { useState, useEffect } from "react";
import { IoTrashBin } from "react-icons/io5";
import { LuImagePlus } from "react-icons/lu";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BeatLoader } from "react-spinners";
import supabase from "../../supabase";
import TopBar from "../../components/TopBar";

const Banners = () => {
  const [banners, setBanners] = useState([]);
  const [open, setOpen] = useState(false);
  const [newBanner, setNewBanner] = useState({
    image_url: "",
  });
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");

  const fetchBanners = async () => {
    let { data: banners, error } = await supabase.from("banners").select("*");
    if (error) console.log("Error fetching banners: ", error);
    else setBanners(banners);

    // Fetch locations
    let { data: locationData, error: locationError } = await supabase
      .from("banners")
      .select("location");
    if (locationError) console.log("Error fetching locations: ", locationError);
    else {
      const uniqueLocations = [
        ...new Set(locationData.map((item) => item.location)),
      ];
      setLocations(uniqueLocations);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleInputChange = (e) => {
    setNewBanner({ ...newBanner, [e.target.name]: e.target.value });
  };

  const handleAddBanner = async () => {
    setLoading(true);

    try {
      const { data: uploadData, error: uploadError } = await supabase.storage
        .from("banners")
        .upload(file.name, file);

      if (uploadError) {
        console.log("Error uploading file: ", uploadError);
        toast.error("Error uploading file");
        setLoading(false);
        return;
      }

      const { data: publicUrlData, error: urlError } = await supabase.storage
        .from("banners")
        .getPublicUrl(file.name);

      if (urlError) {
        console.log("Error fetching public URL: ", urlError);
        toast.error("Error fetching public URL");
        setLoading(false);
        return;
      }

      console.log("publicUrl: ", publicUrlData.publicUrl);

      const { data: insertData, error: insertError } = await supabase
        .from("banners")
        .insert([
          {
            image_url: publicUrlData.publicUrl,
            location: selectedLocation, // Add the selected location to the new banner
          },
        ])
        .select();

      if (insertError) {
        console.log("Error inserting new banner: ", insertError);
        toast.error("Error inserting new banner");
        setLoading(false);
        return;
      }

      console.log("Inserted banner data: ", insertData);

      setBanners([...banners, ...insertData]);
      handleClose();
      toast.success("Banner added successfully");
    } catch (error) {
      console.error("Unexpected error: ", error);
      toast.error("Unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteBanner = async (id) => {
    // Find the banner to be deleted
    setLoading(true);

    const { data: banner, error: findError } = await supabase
      .from("banners")
      .select("image_url")
      .match({ id })
      .single();
    if (findError) {
      console.log("Error finding banner: ", findError);
      return;
    }

    // Delete the banner record from the database
    const { error: deleteError } = await supabase
      .from("banners")
      .delete()
      .match({ id });
    if (deleteError) {
      console.log("Error deleting banner: ", deleteError);
      return;
    }

    // Delete the image file from the bucket
    const fileName = banner.image_url.split("/").pop();
    const { error: deleteFileError } = await supabase.storage
      .from("banners")
      .remove([fileName]);
    if (deleteFileError) {
      console.log("Error deleting file: ", deleteFileError);
      return;
    }

    setBanners(banners.filter((banner) => banner.id !== id));
    setLoading(false);
    toast.success("Banner deleted successfully");
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  return (
    <div className="page h-screen overflow-y-auto  bg-gray-100">
      <TopBar />
      <h1 className="text-4xl font-bold text-center py-6 text-blue-700">
        Banners
      </h1>
      <button
        className="flex items-center justify-center bg-blue-500 text-white rounded px-6 py-3 m-2 hover:bg-blue-600 transition-colors"
        onClick={handleOpen}
      >
        <LuImagePlus className="mr-2" />
        Add Banner
      </button>
      <div
        className={`fixed inset-0 flex items-center justify-center z-10 ${
          open ? "" : "hidden"
        }`}
      >
        <div className="bg-white rounded p-6 m-2 shadow-lg">
          <h2 className="text-3xl font-bold text-center mb-6 text-blue-700">
            Add New Banner
          </h2>
          <label
            htmlFor="location"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Select Location
          </label>
          <select
            id="location"
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            {locations.map((location, index) => (
              <option key={index} value={location}>
                {location}
              </option>
            ))}
          </select>
          <input
            className="border rounded px-3 py-2 w-full mb-3"
            type="file"
            onChange={handleFileChange}
          />
          <div className="flex justify-end">
            <button
              className="bg-gray-300 rounded px-6 py-2 m-2 hover:bg-gray-400 transition-colors"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="bg-blue-500 text-white rounded px-6 py-2 m-2 hover:bg-blue-600 transition-colors"
              onClick={handleAddBanner}
            >
              Add
            </button>
          </div>
        </div>
      </div>
      <h2 className="text-3xl font-bold text-center py-6 text-blue-700">
        Banners List
      </h2>

      <select
        className="ml-10 p-2 font-bold"
        value={selectedLocation}
        onChange={(e) => setSelectedLocation(e.target.value)}
      >
        <option value="">All Locations</option>
        {locations.map((location, index) => (
          <option key={index} value={location}>
            {location}
          </option>
        ))}
      </select>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 p-6">
        {banners &&
          banners
            .filter(
              (banner) =>
                !selectedLocation || banner.location === selectedLocation
            )
            .map(
              (banner) =>
                banner && (
                  <div key={banner.id} className="border rounded p-4 shadow-lg">
                    <img
                      className="w-full h-64 object-cover rounded"
                      src={banner.image_url}
                      alt=""
                    />
                    <button
                      className="flex items-center justify-center bg-red-500 text-white rounded px-4 py-2 mt-3 hover:bg-red-600 transition-colors"
                      onClick={() => handleDeleteBanner(banner.id)}
                    >
                      <IoTrashBin className="mr-2" />
                      Delete
                    </button>
                  </div>
                )
            )}
      </div>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <BeatLoader color="#4299E1" />
        </div>
      )}
    </div>
  );
};

export default Banners;
