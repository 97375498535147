import { useNavigate } from "react-router-dom";
import SideDrawer from "./SideDrawer";
import { TbMenu2 } from "react-icons/tb"; // Import TbMenu2 from react-icons
import TopLogo from "../images/TAQWA.png";
import { useState } from "react";

export const TopBar = ({ avatarInfo }) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const userName = "Admin";
  const avatarPic =
    avatarInfo?.avatar_url ||
    `https://api.dicebear.com/9.x/miniavs/svg?seed=Admin`;

  return (
    <div
      style={{
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      }}
      className="px-2 top-bar w-full h-20 flex justify-between items-center "
    >
      <div className="flex justify-start items-center w-full ">
        <TbMenu2
          size={25}
          onClick={() => {
            setOpen((prevOpen) => !prevOpen);
          }}
          style={{ cursor: "pointer" }}
        />
        <SideDrawer
          name={userName}
          profilePic={avatarPic}
          open={open}
          setOpen={setOpen}
        />
      </div>
      <img
        src={TopLogo}
        alt="Taqwa Logo"
        className="h-10"
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          window.location.href = "/";
        }}
      />
    </div>
  );
};

export default TopBar;
