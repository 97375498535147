import Login from "./pages/Login/Login";
import "./App.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import { SessionContext } from "./components/SessionContext";
import { useEffect, useState } from "react";
import Clock from "./components/clock";
import supabase from "./supabase";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from "./pages/Landing/Landing";
import ProductEdit from "./pages/Landing/ProductEdit";
import { ToastContainer } from "react-toastify";
import ProductNew from "./pages/Landing/ProductNew";
import Orders from "./pages/Landing/Orders";
import OrderDetail from "./pages/Landing/OrderDetail";
import Banners from "./pages/Landing/Banners";

function App() {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (
        session &&
        session.user &&
        session.user.app_metadata &&
        session.user.app_metadata.userrole === "admin"
      ) {
        console.log("Session Role", session.user.app_metadata.userrole);
        setSession(session);
      }
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      if (_event === "SIGNED_OUT") {
        setSession(null);
      } else if (
        session &&
        session.user &&
        session.user.app_metadata &&
        session.user.app_metadata.userrole === "admin"
      ) {
        setSession(session);
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const checkSignInStatus = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1200)); // Simulate a delay
      setLoading(false);
    };

    checkSignInStatus();
  }, []);

  if (loading) {
    return <Clock />;
  }

  return (
    <Provider store={store}>
      <SessionContext.Provider value={{ session, setSession }}>
        <Router>
          <ToastContainer />
          <Routes>
            <Route path="/" element={session ? <Landing /> : <Login />} />
            <Route
              path="/product/:productId"
              element={session ? <ProductEdit /> : <Login />}
            />
            <Route
              path="/addnew"
              element={session ? <ProductNew /> : <Login />}
            />
            <Route path="/orders" element={session ? <Orders /> : <Login />} />
            <Route
              path="/order/:order_id"
              element={session ? <OrderDetail /> : <Login />}
            />
            <Route
              path="/banners"
              element={session ? <Banners /> : <Login />}
            />
          </Routes>
        </Router>
      </SessionContext.Provider>
    </Provider>
  );
}

export default App;
