import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import supabase from "../supabase";
import { useNavigate } from "react-router-dom";

export default function ProductList() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [page, setPage] = useState(0);
  const debouncedSearch = debounce((query) => setSearchQuery(query), 300);

  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts();
    fetchCategories();
  }, [searchQuery, selectedCategory, page]);

  async function fetchProducts() {
    let query = supabase
      .from("products")
      .select("*")
      .order("created_at", { ascending: false })
      .range(page * 30, (page + 1) * 30 - 1);

    if (searchQuery) {
      query = query.ilike("name", `%${searchQuery}%`);
    }

    if (selectedCategory) {
      query = query.eq("category", selectedCategory);
    }

    const { data, error } = await query;

    if (error) {
      console.error(error);
      return;
    }

    setProducts(data);
  }

  async function fetchCategories() {
    const { data, error } = await supabase.from("categories").select("*");

    if (error) {
      console.error(error);
      return;
    }

    setCategories(data);
  }
  return (
    <div className="flex flex-col p-3 ">
      <h1 className="text-center text-2xl font-semibold text-gray-900 mb-4">
        Find and Edit Products
      </h1>

      <input
        className="mb-4 w-full px-3 py-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
        type="text"
        placeholder="Find products..."
        onChange={(e) => debouncedSearch(e.target.value)}
      />

      <select
        className="mb-4 appearance-none bg-white border border-gray-400 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer"
        value={selectedCategory}
        onChange={(e) => setSelectedCategory(e.target.value)}
      >
        <option value="">All categories</option>
        {categories.map((category) => (
          <option key={category.id} value={category.category}>
            {category.category}
          </option>
        ))}
      </select>

      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {products.map((product) => (
          <div
            key={product.id}
            className="flex flex-col items-center p-2 border rounded m-2 cursor-pointer shadow-md"
            onClick={() => {
              navigate(`/product/${product.id}`);
            }}
          >
            <img
              src={product.images[0]}
              alt={product.name}
              className="w-full h-32 object-cover mb-4"
            />
            <div className="w-full text-center overflow-hidden">
              {product.name}
            </div>
          </div>
        ))}
      </div>

      <button
        className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        onClick={() => setPage((prev) => prev + 1)}
      >
        Load more
      </button>
    </div>
  );
}
