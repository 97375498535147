const subBrands = {
  Shoes: ["Adidas", "Nike", "New Balance", "Balenciaga"],
  Watch: [
    "Chain 599",
    "Gshock 799",
    "Vintage 299",
    "Vintage 199",
    "Combo 699",
    "Watch 349",
    "Watch 299",
    "Watch 599",
    "Watch 299",
  ],
};

export default subBrands;
