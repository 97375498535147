import React, { useContext } from "react";
import TopBar from "../../components/TopBar";
import { SessionContext } from "../../components/SessionContext";
import ProductList from "../../components/ProductList";

export default function Landing() {
  const { session } = useContext(SessionContext);

  return (
    <div className="page h-screen overflow-y-scroll">
      <TopBar avatarInfo={session?.user.user_metadata} />
      <ProductList />
    </div>
  );
}
