import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import supabase from "../../supabase";
import moment from "moment";
import TopBar from "../../components/TopBar";
import { SessionContext } from "../../components/SessionContext";

const OrderDetail = () => {
  const { order_id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [user, setUser] = useState(null);

  const { session } = useContext(SessionContext);

  useEffect(() => {
    const fetchOrder = async () => {
      const { data, error } = await supabase
        .from("orders")
        .select("*")
        .eq("order_id", order_id);

      if (error) console.error("Error fetching order: ", error);
      else setOrder(data[0]);
    };

    fetchOrder();
  }, [order_id]);

  const [cod_charge, setCodCharge] = useState(0);

  useEffect(() => {
    if (order) {
      const newCodCharge = Object.values(order.items).reduce(
        (total, item) => total + (item.cod_price ? item.cod_price : 0),
        0
      );
      setCodCharge(newCodCharge);
    }
  }, [order]);

  useEffect(() => {
    const fetchUser = async () => {
      if (order && order.user_id) {
        const { data, error } = await supabase
          .from("users")
          .select("*")
          .eq("id", order.user_id);

        if (error) console.error("Error fetching user: ", error);
        else setUser(data[0]);
      }
    };

    fetchUser();
  }, [order]);

  const updateOrderStatus = async (status) => {
    const { error } = await supabase
      .from("orders")
      .update({ order_status: status })
      .eq("order_id", order_id);

    if (error) {
      console.error("Error updating order status: ", error);
    } else {
      setOrder((prevOrder) => ({ ...prevOrder, order_status: status }));
    }
  };
  if (!order || !user) return <div>Loading...</div>;
  const printDetails = () => {
    let printWindow = window.open("", "_blank");
    printWindow.document.write(`
<html>
<head>
  <title>${order.order_id}</title>
  <style>
    body {
      font-family: Arial, sans-serif;
      color: #333;
      margin: 0;
      padding: 0;
      font-size: 0.8em; /* Reduce font size */
    }
    .invoice-header {
      text-align: center;
      padding: 15px; /* Reduce padding */
      background-color: #f8f8f8;
      border-bottom: 1px solid #ddd;
    }
    .invoice-body {
      padding: 15px; /* Reduce padding */
      border: 1px solid #ddd;
      margin: 15px; /* Reduce margin */
    }
    .invoice-footer {
      text-align: center;
      padding: 15px; /* Reduce padding */
      background-color: #f8f8f8;
      border-top: 1px solid #ddd;
    }
    .section-header {
      font-weight: bold;
      margin-top: 10px; /* Reduce margin */
      margin-bottom: 5px; /* Reduce margin */
      text-transform: uppercase;
      color: #666;
    }
    .item-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px; /* Reduce margin */
      border-bottom: 1px solid #eee;
      padding-bottom: 5px; /* Reduce padding */
    }
    p {
      margin: 0;
    }
  </style>
</head>
<body>
`);
    printWindow.document.write(
      "<div class='invoice-header'><h2>Invoice</h2></div>"
    );
    printWindow.document.write("<div class='invoice-body'>");
    printWindow.document.write("<h3 class='section-header'>Order Details</h3>");
    printWindow.document.write(`<p>Order ID: <b>${order.order_id}</b></p>`);
    printWindow.document.write(`<p>E Biller ID: <b>55938</b></p>`);
    printWindow.document.write(
      `<p>Payment Method: ${order.payment_method}</p>`
    );
    cod_charge &&
      printWindow.document.write(`<p>COD Charge:<b>₹ ${cod_charge}<b></p>`);
    order.payment_id &&
      printWindow.document.write(`<p>Payment Id: ${order.payment_id}</p>`);
    printWindow.document.write(
      `<p>Created At: ${moment(order.created_at).format(
        "MMMM Do YYYY, h:mm:ss a"
      )}</p>`
    );
    printWindow.document.write(`<p>Amount: ₹${order.amount}</p>`);
    printWindow.document.write(
      "<h3 class='section-header'>User Information</h3>"
    );
    printWindow.document.write(
      `<p>Name: ${user.raw_user_meta_data.full_name}</p>`
    );
    printWindow.document.write(`<p>Email: ${user.email}</p>`);
    printWindow.document.write("<h3 class='section-header'>Address</h3>");
    printWindow.document.write(`<p>${order.address.name}</p>`);
    printWindow.document.write(`<p>${order.address.phone}</p>`);
    printWindow.document.write(`<p>${order.address.address}</p>`);
    printWindow.document.write(`<p>${order.address.city}</p>`);
    printWindow.document.write(`<p>${order.address.state}</p>`);
    printWindow.document.write(`<p>${order.address.zip}</p>`);
    printWindow.document.write(`<p>${order.address.country}</p>`);
    printWindow.document.write("<h3 class='section-header'>Items</h3>");
    printWindow.document.write(
      "<table style='width: 100%; border-collapse: collapse;'>"
    );
    printWindow.document.write("<tr style='border-bottom: 1px solid #ddd;'>");
    printWindow.document.write(
      "<th style='text-align: left; padding: 10px;'>Name</th>"
    );
    printWindow.document.write(
      "<th style='text-align: left; padding: 10px;'>Price</th>"
    );
    printWindow.document.write(
      "<th style='text-align: left; padding: 10px;'>Quantity</th>"
    );
    printWindow.document.write(
      "<th style='text-align: left; padding: 10px;'>Category</th>"
    );
    printWindow.document.write(
      "<th style='text-align: left; padding: 10px;'>Size</th>"
    );
    printWindow.document.write("</tr>");

    order.items.forEach((item) => {
      printWindow.document.write("<tr style='border-bottom: 1px solid #eee;'>");
      printWindow.document.write(
        `<td style='padding: 10px;'>${item.name}</td>`
      );
      printWindow.document.write(
        `<td style='padding: 10px;'>₹${
          item.priceMap ? item.priceMap[item.size] : item.price
        }</td>`
      );
      printWindow.document.write(
        `<td style='padding: 10px;'>${item.quantity}</td>`
      );
      printWindow.document.write(
        `<td style='padding: 10px;'>${item.category}</td>`
      );
      if (item.size) {
        printWindow.document.write(
          `<td style='padding: 10px;'>${item.size}</td>`
        );
      } else {
        printWindow.document.write(`<td style='padding: 10px;'>N/A</td>`);
      }
      printWindow.document.write("</tr>");
    });

    printWindow.document.write("</table>");
    printWindow.document.write("<div class='invoice-footer'>");
    printWindow.document.write("<h3 class='section-header'>Contact Us</h3>");
    printWindow.document.write(
      "<p>Address: Shopping Complex, Amballoor - Kanjiramattom Rd, Kunnumpuram, Kanjiramattom, Kerala 682315</p>"
    );
    printWindow.document.write("<p>Phone: +91 9496990907</p>");

    printWindow.document.write("</div>");
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };
  return (
    <>
      <TopBar avatarInfo={session?.user.user_metadata} />
      <div className=" selection page h-screen overflow-y-scroll p-4 bg-gray-100 pb-40 ">
        <h1 className="text-2xl font-bold mb-4 text-blue-500">Order Detail</h1>

        <div className="mb-4 bg-white p-4 rounded shadow">
          <h2 className="text-xl font-semibold mb-2">Items</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {order.items.map((item) => (
              <div key={item.id} className="border p-4 rounded shadow">
                <img
                  src={item.images[0]}
                  alt={item.name}
                  className="w-full h-64 object-cover mb-4 rounded"
                />
                <h3 className="text-lg font-semibold mb-2">{item.name}</h3>
                <p className="mb-2 text-xl font-bold">
                  Price: ₹
                  {item.priceMap ? item.priceMap[item.size] : item.price}
                </p>
                <p className="mb-2 text-xl font-bold">
                  Quantity: {item.quantity}
                </p>
                {item.size !== 0 && (
                  <p className="mb-2 text-xl font-bold">Size: {item.size}</p>
                )}
                <p className="mb-2">Category: {item.category}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="mb-4 bg-white p-4 rounded shadow">
          <h2 className="text-xl font-semibold mb-2">Order Information</h2>
          <p>Order ID: {order.order_id}</p>
          {order.payment_id && <p>Payment Id: {order.payment_id}</p>}
          <p>
            Payment Method:{" "}
            <span
              style={{
                color: order.payment_method === "COD" ? "red" : "green",
                fontWeight: "bold",
              }}
            >
              {order.payment_method === "COD" ? "CASH ON DELIVERY" : "ONLINE"}
            </span>
          </p>
          <p>
            Created At:{" "}
            {moment(order.created_at).format("MMMM Do YYYY, h:mm:ss a")}
          </p>
          <p>Amount: ₹{order.amount}</p>
          <p>
            Status: <span className="text-red-600">{order.order_status}</span>
          </p>
          <select
            value={order.order_status}
            onChange={(e) => updateOrderStatus(e.target.value)}
            className="mt-2 bg-white border border-gray-300 rounded py-2 px-3 text-base shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            <option value="waiting">Waiting</option>
            <option value="shipped">Shipped</option>
            <option value="delivered">Delivered</option>
          </select>
        </div>
        <div className="selection mb-4 bg-white p-4 rounded shadow">
          <h2 className="text-xl font-semibold mb-2">Delivery Address</h2>
          <p>{order.address.name}</p>
          <p>{order.address.phone}</p>
          <p>{order.address.address}</p>
          <p>
            {order.address.city}, {order.address.state} {order.address.zip}
          </p>
          <p>{order.address.country}</p>
        </div>

        <div className="mb-4 bg-white p-4 rounded shadow">
          <h2 className="text-xl font-semibold mb-2">User Information</h2>
          <p>Name: {user.raw_user_meta_data.full_name}</p>
          <p>Email: {user.email}</p>
        </div>

        <div className="flex justify-between">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded"
            onClick={() => navigate("/orders")}
          >
            Back to Orders
          </button>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded"
            onClick={() => printDetails()}
          >
            Print Invoice
          </button>
        </div>
      </div>
    </>
  );
};
export default OrderDetail;
